<template>
  <div id="app">
    <common-navbar></common-navbar>
    <div class="app-wrapper">
      <router-view/>
    </div>
    <common-footer></common-footer>
    <common-right></common-right>
  </div>
</template>

<script>
import commonNavbar from '@/components/commonNavbar'
import commonFooter from '@/components/commonFooter'
import commonRight from '@/components/commonRight'
import DevicePixelRatio from './utils/devicePixelRatio.js';
export default {
  components: {
    commonNavbar,
    commonFooter,
    commonRight
  },
  created(){
     new DevicePixelRatio().init();
  },
}
</script>
<style lang="scss">
@import '@/scss/common.scss';
@import "./scss/font.css";
html {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}
#app {
  background-color: #fff;
  max-width: 2560px;
  margin: auto;
}
.app-wrapper {
  overflow: hidden;
  min-height: 1000px;
}
@media (max-width: 768px){
  .app-wrapper {
    min-height: 500px;
  }
}
</style>
