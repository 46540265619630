import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=> import('@/views/home/Home'),
  },
  {
    path: '/brand',
    name: 'Brand',
    component: ()=> import('@/views/Brand.vue'),
  },
  {
    path: '/brand/jde',
    name: 'jde',
    component: ()=> import('@/views/brandDetail/jde.vue'),
    meta: {
      parent: 'brand'
    }
  },
  {
    path: '/brand/kateSomerville',
    name: 'kateSomerville',
    component: ()=> import('@/views/brandDetail/kateSomerville.vue'),
    meta: {
      parent: 'brand'
    }
  },
  {
    path: '/brand/murad',
    name: 'sepai',
    component: ()=> import('@/views/brandDetail/murad.vue'),
    meta: {
      parent: 'brand'
    }
  },
  {
    path: '/brand/dermalogica',
    name: 'dermalogica',
    component: ()=> import('@/views/brandDetail/dermalogica.vue'),
    meta: {
      parent: 'brand'
    }
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: ()=> import('@/views/news/NewsList.vue'),
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: ()=> import('@/views/news/NewsDetail.vue'),
    meta: {
      parent: 'news'
    }
  },
  {
    path: '/cooperate',
    name: 'Cooperate',
    component: ()=> import('@/views/Cooperate.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: ()=> import('@/views/Help.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition  //游览器返回记住位置
    } else {
      return { x: 0, y: 0 } //滚动到顶
    }
  }
})


export default router
